.c-point {
  counter-reset: pointNum;
  margin-top: 60px;
  &_cmn {
    & + & {
      margin-top: 80px;
    }
    &_inner {
      max-width: 604px;
      width: 95%;
      margin: 48px auto 0;
    }
  }

  &_title {
    position: relative;
    &::before {
      content: "";
      display: block;
      width: 100%;
      height: 1px;
      background: $greenDark;
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(50%);
    }
    p {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 104px;
      height: 104px;
      background: $whiteBase;
      @include fz(18, 16, 700);
      @include lh(1.8,1.8);
      text-align: center;
      color: $greenDark;
      border: 1px solid $green;
      border-radius: 50%;
      margin: 0 auto;
      position: relative;
      z-index: 1;
      &::after {
        counter-increment: pointNum;
        content: counter(pointNum);
        display: block;
      }
    }
  }
}
