.p-top {

  &_about {
    padding-top: 108px;
    // overflow: hidden;
    .c-section_bg {
      background: url(../img/index/about_bg.jpg) right center /cover no-repeat;
    }

    .c-textArea {
      background: rgba(255,255,255,0.8);
      padding: 40px 60px;
      @include sp {
        padding: 20px;
      }
    }
  }

  &_service {
    background: $greenG, url(../img/index/service_bg.jpg) center top /contain no-repeat ;
    background-blend-mode: multiply;

    color: $white;

    &::before {
      content: "";
      display: block;
      width: 100%;
      height: 100%;
      background: url(../img/index/service_svg.svg) left center /cover no-repeat;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      opacity: .2;
    }

    &_cont {
      li + li {
        margin-top: 20px;
        @include sp {
          margin-top: 30px;
        }
      }
    }

    .c-textArea_fig {
      width: 60%;
      margin-left: auto;
      margin-right: auto;
    }
  }

  &_news {
    padding: 0!important;
    &_title {
      padding: 100px 0 40px;
      @include sp {
        padding: 40px 0 20px;
      }
    }

    &_cont {
      background: $white;
      position: relative;
      &_fig {
        display: block;
        width: 43%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        @include sp {
          width: 100%;
          height: 300px;
          position: relative;
          top: auto;
          left: auto;
        }
        &_cont {
          display: block;
          width: 100%;
          height: 100%;
          background: url(../img/index/news_img.png) center center /cover no-repeat;
          @include pc {
            height: 150%;
            position: relative;
            top: -25%;
          }
        }
      }

      &_inner {
        width: 50%;
        padding: 82px 0;
        margin-left: auto;
        margin-right: 0;
        @include sp {
          width: 100%;
          background: $white;
          padding: 20px 5%;
          margin-top: -100px;
          position: relative;
          z-index: 1;
        }
      }
    }

    &_cmn {
      & + & {
        margin-top: 18px;
      }

      &_date {
        display: block;
        @include fz(12, 12);
      }
      a {
        text-decoration: underline;
        display: block;
        margin-top: 6px;
      }
    }
  }

}
