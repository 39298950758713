.c-figre {
  img {
    display: block;
    width: 100%;
  }
  &_text {
    margin-top: 70px;
    & + img {
      margin-top: 70px;
    }
  }
}
