.c-icon {
  &_tel_w {
    &::before {
      content: "";
      display: inline-block;
      width: 10px;
      height: 15px;
      background: url(../img/common/icon_tel_w.png) center center /cover no-repeat;
      margin-right: 8px;
    }
  }
  &_tel_g {
    &::before {
      content: "";
      display: inline-block;
      width: 10px;
      height: 15px;
      background: url(../img/common/icon_tel_g.png) center center /cover no-repeat;
      margin-right: 8px;
    }
  }
}
