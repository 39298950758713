.p-consulting {
  &_merit {
    margin-top: 40px;
    @include sp {
      .c-listText {
        margin-top: 30px;
      }
    }
  }

  &_list {
    img {
      display: block;
      &:nth-child(n+3){
        margin-top: 8%;
      }
    }
  }

  &_case {
    .c-textBlock {
      text-align: center;
    }
  }
}
