.l-header {
  width: 100%;
  height: 80px;
  // padding: 0 40px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000;
  background: $white;

  &_inner {
    max-width: 1200px;
    width: 95%;
    height: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  @include sp {
    &::before {
      content: "";
      display: block;
      width: 100%;
      height: 100vh;
      background: rgba($black, .5);
      position: absolute;
      top: 0;
      left: 0;
      transition: .4s;
      opacity: 0;
      pointer-events: none;
    }
  }
}

.l-header_logo {
  width: 144px;
  @include sp {
    position: relative;
    z-index: 1000;
  }
  a {
    display: block;
    width: 100%;
  }
}

.l-header_btn {
  display: block;
  width: 48px;
  height: 24px;
  position: relative;
  z-index: 1000;
  span,
  &::before,
  &::after {
    content: "";
    display: block;
    width: 100%;
    height: 2px;
    background: $black;
    position: absolute;
    left: 0;
    transition: .2s;
    transform: translateY(0) rotate(0);
  }
  span {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  &::before {
    top: 0;
  }
    &::after {
    bottom: 0;
  }
}

.l-header_nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: calc(100% - 195px);
  height: 100%;
  // padding: 20px;
  @include sp {
    display: block;
    width: 100%;
    height: auto;
    // height: 100vh;
    background: $white;
    padding: 100px 5vw 5vh;
    position: absolute;
    top: 0;
    left: 0;
    transition: .4s;
    transform: translateY(-70%);
    opacity: 0;
    pointer-events: none;
    z-index: 100;
  }
  &_inner {
    @include pc {
      height: 100%;
      display: flex;
      align-items: flex-end;
    }
  }


  &_cmn {
    display: inline-block;
    @include sp {
      display: block;
    }
    & + .l-header_nav_cmn {
      margin-left: 20px;
      @include sp {
        margin-left: 0;
        margin-top: 8vw;
      }
    }
    a {
      display: block;
      @include fz(16, 16, 700);
      @include lh(1, 1);
      color: $greenDark;
      box-sizing: border-box;
      @include pc {
        padding-bottom: 18px;
        border-bottom: 4px solid rgba($greenDark, 0);
        transition: .2s;
      }
      @include sp {
        padding: 4px 0 4px 12px;
        border-left: 4px solid rgba($greenDark, 0);
      }
      &.current {
        @include pc {
          border-bottom: 4px solid $greenDark;
        }
        @include sp {
          border-left: 4px solid $greenDark;
        }
      }
      &:hover {
        @include pc {
          border-bottom: 4px solid $greenDark;
        }
      }
    }
  }

  &_contact {
    @include pc {
      display: flex;
    }
    @include sp {
      margin-top: 20px;
    }
    &_tel {
      @include fz(12,14);
      color: $greenDark;
      @include pc {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        text-align: right;
      }
      @include sp {
        text-align: center;
      }
      a {
        @include fz(16, 20, 700);
        color: $greenDark;
        display: inline-block;
        margin-top: 4px;
      }
    }

    &_button {
      width: 192px;
      border-radius: 26px;
      background: linear-gradient(
      to right,
      $greenDark,
      $green,
      $greenLight,
      );
      margin-left: 20px;
      position: relative;
      @include sp {
        margin: 20px auto 0;
      }
      &::before {
        content: "";
        display: block;
        width: 100%;
        height: 100%;
        background: linear-gradient(
        to right,
        $greenDark,
        $green,
        );
        border-radius: 26px;
        position: absolute;
        top: 0;
        left: 0;
        transition: .2s;
        opacity: 1;
      }
      a {
        display: block;
        width: 100%;
        @include fz(16, 14, 700);
        @include lh(1,1);
        text-align: center;
        color: $white;
        padding: 16px 4px;
        position: relative;
      }

      &:hover {
        &::before {
          opacity: 0;
        }

        a::after {
          right: 18px;
        }
      }
    }
  }
}

.l-header.active {
  &::before {
    opacity: 1;
    pointer-events: auto;
  }
  .l-header_btn {
    span {
      width: 0;
    }
    &::before {
      top: 50%;
      transform: translateY(-50%) rotate(45deg);
    }
    &::after {
      bottom: 50%;
      transform: translateY(50%) rotate(-45deg);
    }
  }
  .l-header_nav {
    opacity: 1;
    transform: translateY(0);
    pointer-events: auto;
  }
}
