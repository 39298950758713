.l-kv {
  display: block;
  width: 100%;
  min-height: 430px;
  position: relative;
  z-index: 10;
  background-color: rgba($green, 0.6);
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  background-blend-mode: multiply;
  @include sp {
    min-height: 320px;
  }
  &_inner {
    max-width: 900px;
    width: 90%;
    height: 100%;
    margin: 0 auto;
    text-align: center;
    color: $white;
    position: relative;
    z-index: 1;
  }

  &_catch {
    @include fz(80, 50);
    @include lh(1,1)
    letter-spacing: 10px;
    font-family: $font_meno;
    font-style: italic;
  }
  &_title {
    @include fz(42, 30, 700);
    @include lh(1,1)
    margin-top: 70px;
    @include sp {
      margin-top: 40px;
    }
  }
  &_text {
    @include fz(16, 14);
    @include lh(2, 2);
    margin-top: 34px;
    padding-bottom: 20px;
  }
}

.l-kv--mini {
  @extend .l-kv;
  min-height: 0;
  height: 260px;
  @include sp {
    min-height: 0;
    height: 200px;
  }
  .l-kv_title {
    width: 95%;
    margin-top: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.l-kv--top {
  width: 100%;
  height: calc(670 / 1280 * 100vw);
  background: url(../img/index/kv_bg.jpg) center center /cover no-repeat;
  position: relative;
  z-index: 10;

  @include sp {
    height: calc(568 / 375 * 100vw);
    background: url(../img/index/kv_bg_sp.jpg) center top /cover no-repeat;
  }

  &_inner {
    // width: calc(484 / 1280 * 100%);
    width: 95%;
    text-align: center;
    color: $greenDark;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -68%);
    @include sp {
      transform: translate(-50%, -70%);

    }
  }

  &_catch {
    @include fz_vw(64, 42, 400);
    line-height: 1em;
    letter-spacing: calc(10 / 1280 * 100vw);
    font-family: $font_yumin;
    .j-start {
      color: #dad545;
      opacity: 0;
      @for $i from 1 through 8 {
        $speed: 1.1 + ($i / 11)  + s;
        &:nth-of-type(#{$i}){
          transition: color .6s $speed, opacity .4s $speed;
        }
      }
      &.action {
        color: $greenDark;
        opacity: 1;
      }
    }
  }
  &_text {
    @include fz_vw(16, 12.5);
    line-height: 2.2em;
    margin-top: calc(32 / 1280 * 100vw);
    @include sp {
      margin-top: calc(20 / 375 * 100vw);
    }
    // &.j-start {
    //   opacity: 0;
    //   position: relative;
    //   transition: .7s 2.4s;
    //   &.action {
    //     opacity: 1;
    //   }
    // }
    &.j-start {
      color: #dad545;
      opacity: 0;
      transition: color .9s 2.4s, opacity .6s 2.4s;
      &.action {
        color: $greenDark;
        opacity: 1;
      }
    }
  }

  &_scroll {
    position: absolute;
    bottom: calc(32 / 1280 * 100vw);
    left: 50%;
    transform: translateX(-50%);
    a {
      display: inline-block;
      @include fz_vw(12, 12);
      letter-spacing: 1px;
      color: $greenDark;
      margin-bottom: calc(8 / 1280 * 100vw);
    }
    .c-line {
      height: calc(80 / 1280 * 100vw);
      margin-top: calc(10 / 1280 * 100vw);
      margin-bottom: 0;
    }
  }
}

.l-kv--about {
  background-image: url(../img/about/kv_bg.jpg);
  background-position: 80% center;
}
.l-kv--service {
  background-image: url(../img/service/kv_bg.jpg);
}
.l-kv--advisor {
  background-image: url(../img/service/advisor/kv_bg.jpg);
}
.l-kv--consulting {
  background-image: url(../img/service/consulting/kv_bg.jpg);
}
.l-kv--training {
  background-image: url(../img/service/training/kv_bg.jpg);
}
.l-kv--acting {
  background-image: url(../img/service/acting/kv_bg.jpg);
}
.l-kv--news {
  background-image: url(../img/news/kv_bg.jpg);
}
