.u-color--green {
  color: $greenDark;
}
.u-color--white {
  color: $white;
}

.u-fw--bold {
  font-weight: bold;
}

.u-text--center {
  text-align: center;
}
