.c-section {
  padding: 82px 0 80px;
  position: relative;
  @include sp {
    padding: 32px 0 30px;
  }

  &_bg {
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    @include pc {
      height: 120%;
      top: -10%;
    }
  }

  &_block {
    width: 100%;

    &--wall {
      background: url(../img/common/bg_wall.jpg) center top /cover no-repeat;
    }
  }

  &_inner {
    max-width: 900px;
    width: 90%;
    margin: 0 auto;
    position: relative;
  }
  &_inner--mini {
    @extend .c-section_inner;
    max-width: 604px;
  }

  &_catch {
    // content: "";
    @include fz(100);
    font-weight: 200;
    font-style: italic;
    font-family: $font_meno;
    writing-mode: vertical-rl;
    // text-orientation: mixed;
    text-orientation: sideways;
    letter-spacing: 16px;
    color: $greenDark;
    position: absolute;
    top: 0;
    // transform: translateX(calc(-50% + 450px));
    left: 100%;
    // transform: translateX(450px);
    z-index: 10;
  }
  &:nth-child(even) .c-section_catch {
    left: auto;
    right: 100%;
    // transform: translateX(-450px);
  }
}

.c-section--wall {
  @extend .c-section;
  background: url(../img/common/bg_wall.jpg) center top /cover no-repeat;
}
.c-section--green {
  @extend .c-section;
  background: $greenG;
  color: $white;
  .c-title {
    color: $white;
    &::after {
      @extend .c-line--white;
    }
  }
}
