@charset "UTF-8";

// black
$black: #2e2e2e;

// white
$white: #fff;
$whiteBase: #f7f5f5;

// gray
$gray: #d5d5d5;


//site color

//blue
$blueLight: #eaf2f2;

//green
$green: #1f7f65;
$greenLight: #a2c86c;
$greenDark: #005854;
$greenG: linear-gradient(to right, $greenDark, $green);



//template color
// $cotta: #ED6A5A;
// $bud: #F4F1BB;
// $opal: #9BC1BC;
// $cadet_blue: #5CA4A9;
// $alabaster: #E6EBE0;


// @import url(//fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@300;400;500;700&display=swap);
// @import url(//fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,700;1,100&display=swap);
//
// $font_noto: 'Noto Sans', 'Noto Sans JP', "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "游ゴシック", YuGothic, "メイリオ", Meiryo, sans-serif;
// $font_robo: 'Roboto', 'Noto Sans', 'Noto Sans JP', sans-serif;


/* 游ゴシック体 */
$font_gothic: "游ゴシック体", "Yu Gothic", YuGothic, "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, "MS Pゴシック", "MS PGothic", sans-serif;

/* 游明朝体 */
$font_yumin: "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro", "Hiragino Mincho Pro", "MS P明朝", "MS PMincho", serif;

$font_meno: meno-banner-condensed, "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro", "Hiragino Mincho Pro", "MS P明朝", "MS PMincho", serif;
$font_mont: montserrat, "游ゴシック体", "Yu Gothic", YuGothic, "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, "MS Pゴシック", "MS PGothic", sans-serif;
