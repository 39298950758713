.c-textArea {
  &_title {
    @include fz(24, 20, 700);
    // margin-bottom: 14px;
    & + .c-textArea_text,
    & + .c-textArea_fig {
      margin-top: 14px;
    }
  }
  &_title--big {
    @include fz(32, 22, 700);
    // margin-bottom: 30px;
    & + .c-textArea_text,
    & + .c-textArea_fig {
      margin-top: 30px;
    }
  }
  &_fig {
    width: 100%;
    // margin-bottom: 14px;
    & + .c-textArea_text {
      margin-top: 14px;
    }
  }
  &_text {
    & + .c-textArea_fig {
      margin-top: 46px;
    }
    & + .c-textArea_sub {
      margin-top: 40px;
    }
  }
  &_sub {
    @include fz(12, 12);
    @include lh(1.8, 1.8);

  }
}
.c-textArea--half {
  @include pc {
    width: 50%;
    padding: 0 6%;
  }
}
.c-textArea--center {
  text-align: center;
}
