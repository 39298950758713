.c-form {
  max-width: 700px;
  width: 90%;
  margin: 94px auto 0;

  &_tel {
    margin-bottom: 42px;
    &::before {
      width: 16px;
      height: 24px;
    }
    a {
      @include fz(26, 20,700);
      @include lh(1,1);
      color: $greenDark;
    }
    span {
      display: inline-block;
      @include fz(16, 14);
      @include lh(1,1);
      color: $greenDark;
      margin-left: 24px;
    }
  }

  &_cmn {
    & + & {
      margin-top: 20px;
    }

    &_input {
      width: 100%;
      input,
      textarea {
        display: block;
        width: 100%;
        height: 50px;
        background: $white;
        border: 1px solid $greenDark;
        padding: 10px 20px;
      }
      textarea {
        height: 240px;
        overflow: auto;
      }
    }
  }

  &_privacypolicy {
    background: $blueLight;
    padding: 52px 0 56px;
    margin-top: 48px;
    &_inner {
      max-width: 580px;
      width: 90%;
      margin: 0 auto;
    }
    &_cont {
      height: 170px;
      background: $white;
      padding: 24px;
      margin-top: 22px;
      overflow: auto;
      p {
        @include fz(12, 12);
        @include lh(1.5, 1.5);
        & + p {
          margin-top: 22px;
        }
      }
    }
  }

  &_text {
    text-align: center;
    color: $greenDark;
    margin-top: 36px;
  }
  &_check {
    text-align: center;
    margin-top: 26px;
    input {
      & + span:not(.error) {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        &::before {
          content: "";
          display: inline-block;
          width: 20px;
          height: 20px;
          background: $white;
          border: 1px solid $black;
          margin-right: 16px;
          transform: rotate(0);
          position: relative;
          bottom: 0;
          transition: .2s;
        }
        // &::after {
        //   content: "";
        //   display: block;
        //   width: 10px;
        //   height: 20px;
        //   border-bottom: 3px solid $green;
        //   border-right: 3px solid $green;
        //   position: absolute;
        //   top: 10px;
        //   left: 10px;
        //   transform: rotate(45deg);
        // }
      }
      &:checked + span:not(.error)::before {
        width: 10px;
        height: 20px;
        background: transparent;
        border-top: 1px solid transparent;
        border-left: 1px solid transparent;
        border-bottom: 3px solid $green;
        border-right: 3px solid $green;
        margin-right: 24px;
        bottom: 6px;
        transform: rotate(45deg);
      }
    }
  }

  &_button {
    max-width: 280px;
    width: 90%;
    a,input,button {
      &::after {
        display: none!important;
      }
    }
  }

  .mw_wp_form_confirm {
    .c-form_nextNone {
      display: none;
    }
  }


}
