.p-about {
  &_greeting {
    &_text {
      @include lh(2,2);
    }
    &_cont {
      font-family: $font_yumin;
      text-align: right;
      margin-top: 72px;
      @include sp {
        margin-top: 32px;
      }
      span {
        display: block;
      }
      &_position {
        @include fz(18, 16);
        @include lh(1,1);
      }
      &_name {
        @include fz(28, 20);
        @include lh(1,1);
        margin-top: 16px;
      }
    }
  }
}
