.p-service {
  &_cont {
    background: $whiteBase;
    background-blend-mode: multiply;

    &_inner {
      li + li {
        margin-top: 20px;
        @include sp {
          margin-top: 30px;
        }
      }
    }
    .c-textArea_title,
    .c-countList_count {
      color: $greenDark;
    }

    .c-textArea_fig {
      width: 60%;
      margin-left: auto;
      margin-right: auto;
    }
  }
}
