@mixin pc($width: 769px) {
	@media screen and (min-width: $width) {
		@content;
	}
}

@mixin sp($width: 768px) {
	@media screen and (max-width: $width) {
		@content;
	}
}
@mixin spMini($width: 500px) {
	@media screen and (max-width: $width) {
		@content;
	}
}

@mixin pcMin($width: 1200px) {
	@media screen and (min-width: $width) {
		@content;
	}
}

@mixin frame {
	@include pc {
		width: 100vw;
		width: 100vw;
		height: $frameSizePc;
		padding-right: 35px;
		padding-left: 35px;
		box-sizing: border-box;
	}

	@include sp {
		width: 100%;
		height: calc(100% - 4vw);
	}
}


// font-size
@mixin fz($font_pc: "", $font_sp: "", $b_pc:"", $b_sp: ""){
  font-size: #{$font_pc}px;
  font-size: #{$font_pc / 10}rem;
  line-height: 1.3em;

  @if ($font_sp != "") {
    @include sp {
      font-size: #{$font_sp}px;
      font-size: #{$font_sp / 10}rem;
      line-height: 1.3em;
    }
  }
  @if ($b_pc != "") {
    font-weight: #{$b_pc};
  }
  @if ($b_sp != "") {
    @include sp {
      font-weight: #{$b_sp};
    }
  }
}

@mixin lh($lineH_pc: "", $lineH_sp: ""){
	line-height: #{$lineH_pc}em;
	@if ($lineH_sp != "") {
		@include sp {
			line-height: #{$lineH_sp}em;
		}
	}
}

// font-size for vw
@function get_vw($size, $viewport: 1280) {
	$rate: 100 / $viewport;
	@return $rate * ($size) * 1vw;
}
@function get_vw_sp($size, $viewport: 375) {
  $rate: 100 / $viewport;
  @return $rate * ($size) * 1vw;
}

@mixin fz_vw($font_pc: "", $font_sp: "", $b_pc:"", $b_sp: ""){
  font-size: $font_pc * 1px;
  font-size: get_vw($font_pc);

  @if ($font_sp != "") {
    @include sp {
      font-size: $font_sp * 1px;
      font-size: get_vw_sp($font_sp);
    }
  }

  @if ($b_pc != "") {
    font-weight: #{$b_pc};
  }
  @if ($b_sp != "") {
    @include sp {
      font-weight: #{$b_sp};
    }
  }
}
