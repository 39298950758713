.c-pager {
  margin-top: 76px;
  display: flex;
  justify-content: center;
  @include sp {
    margin-top: 40px;
  }
  .page-numbers {
    display: block;
    width: 50px;
    height: 50px;
    @include fz(16, 14);
    line-height: 50px;
    color: $greenDark;
    text-align: center;
    @include sp {
      width: 30px;
      height: 30px;
      line-height: 30px;
    }
    &.current {
      background: $greenDark;
      color: $white;
    }
    &:not(.dots) {
      border: 1px solid $greenDark;
      & + .page-numbers:not(.dots) {
        margin-left: 20px;
      }
    }
    &.next,
    &.prev {
      display: none;
    }
  }
}
