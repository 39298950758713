
.j-fadein {
  opacity: 0;
  transition: opacity .3s ease-out;
  &.action {
    opacity: 1;
  }
}
.j-fadeleft {
  opacity: 0;
  transition: .3s ease-out;
  position: relative;
  left: -10px;
  &.action {
    opacity: 1;
    left: 0;
  }
}
.j-faderight {
  opacity: 0;
  transition: .3s ease-out;
  position: relative;
  right: -10px;
  &.action {
    opacity: 1;
    right: 0;
  }
}
.j-fadeup {
  opacity: 0;
  transition: .3s ease-out;
  position: relative;
  top: 10px;
  &.action {
    opacity: 1;
    top: 0;
  }
}
.j-slide {
  position: relative;
  overflow: hidden;
  &::before,
  &::after {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  	transition: all .95s cubic-bezier(0.230, 1.000, 0.320, 1.000);
  }
  &::before {
    background: $white;
    z-index: 10;
  }
  &::after {
    background: $greenDark;
    z-index: 1;
    transition-delay: .4s;
  }
  &.slide-gray {
    &::before {
      background: $white;
    }
  }
  &.slide-white {
    &::after {
      background: $gray;
    }
  }

  &.action {
    &::before,
    &::after {
      left: 100%;
    }
  }
}
.j-popup {
  opacity: 0;
  transform: scale(0);
  transform-origin: center bottom;
  transition: .4s ease-out;
  &.action {
    opacity: 1;
    transform: scale(1);
  }
}
