.c-circle {
  height: 0;
  padding-top: 100%;
  border-radius: 50%;
  background: $white;
  position: relative;

  &_inner {
    width: 70%;
    position: absolute;
    top: 18%;
    left: 50%;
    transform: translate(-50%, 0%);
  }
}
