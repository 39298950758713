.c-textList {
  width: 100%;
  border-top: 1px solid $gray;
  border-bottom: 1px solid $gray;
  margin-top: 60px;
  &_cmn {
    display: flex;
    padding: 16px 0;

    & + & {
      border-top: 1px solid $gray;
    }

    &_name {
      width: 130px;
    }
    &_text {
      width: calc(100% - 130px - 10px);
      a {
        color: $greenDark;
        text-decoration: underline;
      }
    }
  }
}
.c-textList--link {
  @extend .c-textList;
  .c-textList_cmn {
    display: block;
    padding: 0;
    &_link {
      display: flex;
      padding: 16px 0;
    }
  }
}
