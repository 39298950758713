.c-breadcrumbs {
  width: 100%;
  background: $blueLight;
  padding: 18px 0;
  &_inner {
    max-width: 900px;
    width: 90%;
    margin: 0 auto;
    @include fz(12,12);
    @include lh(1,1);
    color: $black;
  }
  a,span {
    display: inline-block;
  }
  a {
    span {
      text-decoration: underline;
    }
  }
  .itemListElement,
  .itemListElement_next {
    margin-right: 6px;
  }
}
