.c-flow {
  margin-top: 138px;
  position: relative;
  @include pc {
    display: flex;
    justify-content: space-between;
    padding-top: 40px;
  }
  @include sp {
    width: 80%;
    margin-left: auto;
    margin-right: 0;
  }
  &::before {
    content: "";
    display: block;
    width: 75%;
    height: 1px;
    background: $greenDark;
    position: absolute;
    top: 10px;
    left: 50%;
    transform: translateX(-50%);
    @include sp {
      width: 1px;
      height: 78%;
      top: 0;
      left: calc(-10% - 4px);
      transform: none;
    }
  }
  &_cmn {
    color: $greenDark;
    padding: 0 4%;
    position: relative;
    @include pc {
      width: 25%;
    }
    @include sp {
      // margin-left: auto;
      // margin-right: 0;
      & + & {
        margin-top: 8%;
      }
    }
    &::before {
      content: "";
      display: block;
      width: 20px;
      height: 20px;
      background: $greenDark;
      border-radius: 50%;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translate(-50%, -200%);
      @include sp {
        top: 0;
        left: 0%;
        transform: translate(-200%, 0);
      }
    }

    &_catch {
      @include pc {
        text-align: center;
      }
      span {
        display: block;
      }
      &_en {
        @include fz(20, 18);
        @include lh(1,1);
        font-family: $font_meno;
      }
      &_ja {
        @include fz(18, 16, 700);
        @include lh(1,1);
        margin-top: 18px;
      }
    }
    &_text {
      margin-top: 20px;
    }
  }
}

.c-flow--box {
  counter-reset: flowNum;
  &_group {
    position: relative;
    &::after {
      content: "";
      display: block;
      width: 55px;
      height: 265px;
      background: url(../img/service/acting/flow_sub.svg) center center /contain no-repeat;
      position: absolute;
      top: 50%;
      right: 8%;
      transform: translateY(-50%);
      @include sp {
        right: 0;
      }
    }
    & + .c-flow--box_cmn {
      margin-top: 66px;
      &::before {
        content: "";
        display: block;
        width: 0;
        height: 0;
        border-style: solid;
        border-color: rgba($greenDark, 0.5) transparent transparent transparent;
        border-width: 18px 22px 0 22px;
        position: absolute;
        top: -44px;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }
  &_cmn {
    max-width: 600px;
    width: 80%;
    min-height: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @include fz(16, 14, 700);
    @include lh(2,2);
    border: 1px solid $greenDark;
    padding: 20px 7% 20px 16%;
    position: relative;

    margin: 0 auto;
    @include sp {
      margin-left: 0;
      margin-right: auto;
    }
    & + & {
      margin-top: 66px;
      &::before {
        content: "";
        display: block;
        width: 0;
        height: 0;
        border-style: solid;
        border-color: rgba($greenDark, 0.5) transparent transparent transparent;
        border-width: 18px 22px 0 22px;
        position: absolute;
        top: -44px;
        left: 50%;
        transform: translateX(-50%);
      }
    }

    &::after {
      counter-increment: flowNum;
      content: counter(flowNum, decimal-leading-zero);
      display: block;
      @include fz(50, 20, 100);
      @include lh(1,1);
      font-style: italic;
      letter-spacing: 4px;
      font-family: $font_meno;
      color: $greenDark;
      position: absolute;
      top: 50%;
      left: 7%;
      transform: translateY(-50%);
    }
  }
  &_cmn--green {
    background: $greenG;
    color: $white;
    &::after {
      color: $white;
    }
  }
}
