.c-countList {
  counter-reset: num;
  &_count::before {
    counter-increment: num;
    content: counter(num, decimal-leading-zero);
    @include fz(80, 30);
    @include lh(1,1);
    letter-spacing: 4px;
    font-weight: 100;
    font-family: $font_mont;
  }
}
