.c-table {
  display: block;
  width: 100%;
  margin-top: 26px;
  // border-top: 1px solid $black;
  border: 1px solid $black;
  tbody {
    display: block;
    width: 100%;
  }
  tr {
    display: flex;
    width: 100%;
    @include fz(14, 14);
    @include lh(1.5,1.5);
    text-align: center;
    & + tr {
      border-top: 1px solid $black;
    }
    th {
      width: 240px;
      background: $blueLight;
      padding: 14px 4px;
    }
    td {
      width: calc(100% - 240px);
      padding: 14px 4px;
      border-left: 1px solid $black;
    }
  }
}
