.l-footerContact {
  display: block;
  text-align: center;
  padding: 0;
  &--wall {
    background: url(../img/common/bg_wall.jpg) center bottom /cover no-repeat;
    padding: 70px 0 78px;
    @include sp {
      padding: 30px 0 34px;
    }
  }
  &_catch {
    @include fz(24, 18, 700);
  }
  .c-button {
    margin: 30px auto 0;
    @include sp {
      margin-top: 20px;
    }
  }

  &_tel {
    @include fz(16, 14);
    background: $blueLight;
    padding: 24px 0;
    a {
      display: inline-block;
      @include fz(32, 20, 700);
      color: $greenDark;
      margin: 0 30px;
      @include sp {
        display: block;
        margin: 6px 0;
      }
    }
  }
}
.l-footer {
  display: block;
  background: $greenG;
  color: $white;
  position: relative;
  a {
    color: $white;
  }

  &_inner {
    max-width: 900px;
    width: 90%;
    display: flex;
    justify-content: space-between;
    padding: 74px 0 48px;
    margin: 0 auto;
    @include pc {
      position: relative;
    }
    @include sp {
      padding: 28px 0 20px;
    }
  }

  &_logo {
    width: 116px;
    @include sp {
      width: 80px;
      margin: 0 auto;
    }
  }
  &_list {
    display: flex;
    justify-content: center;
    @include fz(16, 14, 700);
    @include lh(1,1);
    width: calc(100% - 130px);
    @include sp {
      display: none;
    }
    &_cmn {
      & + & {
        margin-left: 6%;
      }
      a {
        display: block;
        & + a {
          margin-top: 20px;
        }
      }
      span {
        display: block;
        @include fz(12, 12, 400);
        @include lh(1,1);
        margin-top: 12px;
      }

      &_inner {
        & + & {
          margin-top: 58px;
        }
      }
    }
  }

  &_pagetop {
    width: 40px;
    height: 40px;
    background: url(../img/common/icon_scrolltop.png) center center /contain no-repeat;
    position: absolute;
    top: 0;
    right: 0;
    @include pc {
      transform: translateX(50%);
    }
    button,a {
      display: block;
      width: 100%;
      height: 100%;
    }
  }

  &_copy {
    background: $white;
    @include fz(12, 12, 400);
    text-align: center;
    color: $black;
    padding: 14px 5%;
  }
}
