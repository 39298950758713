.l-start {
  background: $white;
  width: 100%;
  height: 100vh;
  position: fixed;
  top:0;
  left: 0;
  z-index: 11000;
  pointer-events: none;
  opacity: 1;
  transition: opacity .3s ease-out;
  transition-delay: .8s;
  &.action {
    opacity: 0;
  }
}
