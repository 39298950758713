.c-listText {
  &_title {
    @include fz(18, 16, 700);
    color: $greenDark;
  }

  &_list {
    margin-top: 40px;
    counter-reset: listnum;
    &_cmn {
      @include fz(16, 14, 700);
      @include lh(2,2);
      padding-left: 30px;
      position: relative;
      & + & {
        margin-top: 32px;
      }

      &::before {
        counter-increment: listnum;
        content: counter(listnum)'.';
        @include fz(30, 26, 700);
        @include lh(1,1);
        letter-spacing: 2px;
        font-family: $font_meno;
        font-style: italic;
        color: $greenDark;
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }
}
