.c-line {
  display: block;
  width: 1px;
  height: 80px;
  margin: 32px auto;
  position: relative;
  // overflow: hidden;
  background: linear-gradient(
		to bottom,
		rgba($greenDark, 1) 50%,
		rgba($greenDark, 0) 50%
	);
	background-position: 0 50%;
	background-size: 100% 200%;
	animation: scrolldown 2.2s cubic-bezier(0.76, 0, 0.3, 1) forwards infinite;

  @keyframes scrolldown {
		0% {
			background-position: 0 100%;
		}
		75% {
			background-position: 0 0;
		}
		100% {
			background-position: 0 -100%;
		}
	}
  @include sp {
    height: 40px;
    margin: 20px auto;
  }
}
.c-line--white {
  display: block;
  width: 1px;
  height: 80px;
  margin: 32px auto;
  position: relative;
  // overflow: hidden;
  background: linear-gradient(
		to bottom,
		rgba($white, 1) 50%,
		rgba($white, 0) 50%
	);
	background-position: 0 50%;
	background-size: 100% 200%;
	animation: scrolldown 2.2s cubic-bezier(0.76, 0, 0.3, 1) forwards infinite;

  @keyframes scrolldown {
		0% {
			background-position: 0 100%;
		}
		75% {
			background-position: 0 0;
		}
		100% {
			background-position: 0 -100%;
		}
	}
  @include sp {
    height: 40px;
    margin: 20px auto;
  }
}
