.c-textBlock {
  margin-top: 48px;
  @include pc {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  &_cmn {
    @include pc {
      width: 46%;
    }
    background: $white;
    color: $greenDark;
    @include pc {
      &:nth-child(n+3){
        margin-top: 8%;
      }
    }
    @include sp {
      & + &{
        margin-top: 8%;
      }
    }
    &_inner {
      width: 90%;
      padding: 30px 0 46px;
      margin: 0 auto;
    }
    figure {
      width: 75%;
    }
    &_title {
      @include fz(20, 18, 700);
      text-align: center;
      padding: 18px 0;
      border-bottom: 1px solid $greenDark;
    }
    &_catch {
      width: 90%;
      background: $greenDark;
      @include fz(20, 18, 700);
      text-align: center;
      color: $white;
      padding: 16px 0;
      margin: 0 auto;
      & + .c-textBlock_cmn_text {
        margin-top: 32px;
      }
    }
    &_img {
      & + .c-textBlock_cmn_text {
        margin-top: 32px;
      }
    }
    &_text {
      span {
        color: $greenDark;
        display: block;
        font-weight: bold;
      }

      & + .c-textBlock_cmn_text,
      & + .c-textBlock_cmn_text--brackets {
        margin-top: 36px;
      }
    }
    &_text--brackets {
      width: 100%;
      background: $white;
      padding: 0 5%;
      position: relative;
      &::before,
      &::after {
        content: "";
        display: block;
        width: 10px;
        height: 100%;
        background: url(../img/common/icon_brackets.svg) center center /contain no-repeat;
        position: absolute;
        top: 50%;
      }
      &::before {
        left: 0;
        transform: translateY(-50%);
      }
      &::after {
        right: 0;
        transform: translateY(-50%) rotate(180deg);
      }
      & + .c-textBlock_cmn_text,
      & + .c-textBlock_cmn_text--brackets {
        margin-top: 36px;
      }
    }
  }
}
.c-textBlock--border {
  @extend .c-textBlock;
  .c-textBlock_cmn {
    background: none;
    border-radius: 10px;
    border: 1px solid $greenDark;
    &_text {
    }
  }
  .c-textBlock_cmn--green {
    @extend .c-textBlock_cmn;
    background: $greenDark;
    color: $white;
    .c-textBlock_cmn_title {
      border-bottom: 1px solid $white;
    }
  }
}
