.c-title {
  @include fz(32, 20);
  text-align: center;
  color: $greenDark;
  &::after {
    content: "";
    @extend .c-line;
    // margin-top: 32px;
  }
  &_text {
    @include fz(16, 14);
    @include lh(2, 2);
    margin-top: 32px;
  }
}
.c-title--white {
  @extend .c-title;
  color: $white;
  &::after {
    content: "";
    @extend .c-line--white;
  }
}
.c-title--full {
  @extend .c-title;
  width: 100%;
  background: $greenG;
  text-align: left;
  color: $white;
  padding: 60px 0;
  @include sp {
    padding: 30px 0;
  }
  &::after {
    display: none!important;
  }
  &_cont {
    max-width: 900px;
    width: 90%;
    margin: 0 auto;
  }
}
