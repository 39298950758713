.pc {
  @include pc {
    display: block;
  }

  @include sp {
    display: none!important;
  }
}

.sp {
  @include pc {
    display: none!important;
  }

  @include sp {
    display: block;
  }
}

* {
  box-sizing: border-box;
}

html {
	-webkit-text-size-adjust: 100%;
  font-size: 10px;
}

body {
  font-size: 10px;
  font-family: $font_gothic;
  color: $black;
  background: $whiteBase;
}

a {
  color: $black;
}

img {
  display: block;
  width: 100%;
  height: auto;
}

main {
  @include fz(16, 14);
  @include lh(2, 1.6);
  display: block;
  padding-top: 80px;
  overflow: hidden;
}
