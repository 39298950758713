.c-button {
  width: 240px;
  margin-top: 30px;
  border-radius: 26px;
  background: linear-gradient(
  to right,
  $greenDark,
  $green,
  $greenLight,
  );
  position: relative;
  @include sp {
    margin-left: auto;
    margin-right: auto;
  }
  &::before {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    background: linear-gradient(
    to right,
    $greenDark,
    $green,
    );
    border-radius: 26px;
    position: absolute;
    top: 0;
    left: 0;
    transition: .2s;
    opacity: 1;
  }
  a,input,button {
    display: block;
    width: 100%;
    @include fz(16, 14, 700);
    @include lh(1,1);
    color: $white;
    padding: 18px 24px;
    position: relative;
    &::after {
      content: "";
      display: block;
      width: 10px;
      height: 12px;
      background: url(../img/common/icon_button_direction.png) center center /contain no-repeat;
      position: absolute;
      top: 50%;
      right: 24px;
      transform: translateY(-50%);
      transition: .2s;
    }
  }

  @include pc {
    &:hover {
      &::before {
        opacity: 0;
      }

      a::after {
        right: 18px;
      }
    }
  }

  &--center {
    margin: 30px auto 0;
  }
}

.c-button--line {
  @extend .c-button;
  background: none;
  &::before {
    display: none;
  }

  a {
    border-radius: 26px;
    border: 1px solid $white;
    background: rgba($white, 0);
    transition: .2s;
    &::before {
      content: "";
      display: block;
      width: 10px;
      height: 12px;
      background: url(../img/common/icon_button_direction_g.png) center center /contain no-repeat;
      position: absolute;
      top: 50%;
      right: 24px;
      transform: translateY(-50%);
      transition: .2s;
      opacity: 0;
    }
  }

  @include pc {
    &:hover {
      a {
        border: 1px solid $greenDark;
        background: rgba($white, 1);
        color: $greenDark;
      }
      a::after {
        right: 18px;
        opacity: 0;
      }
      a::before {
        right: 18px;
        opacity: 1;
      }
    }
  }
}
