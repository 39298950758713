  .c-flex {
    @include pc {
      display: flex;
      justify-content: space-between;

      // @for $i from 1 through 100 {
      //   .c-flex_#{$i} {
      //     width: #{$i}%;
      //   }
      // }
    }
    $flex: 2;
    @while $flex < 100 {
      .c-flex_#{$flex} {
        @include pc {
          width: calc(#{$flex}0% / 10);
        }
        @include sp {
          margin-top: 20px;
        }
      }
      $flex: $flex + 2;
    }
  }
  .c-flex--wrap {
    @include pc {
      flex-wrap: wrap;
    }
  }
  .c-flex--alignCenter {
    @extend .c-flex;
    align-items: center;
  }

  .c-reflex {
    .c-flex:nth-child(even) {
      flex-direction: row-reverse;
    }
  }
  .c-flex--re {
    @extend .c-flex;
    flex-direction: row-reverse;
  }
