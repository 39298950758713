.c-halfArea {
  background: $whiteBase;
  position: relative;
  &_fig {
    display: block;
    width: 50.5%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    @include sp {
      width: 100%;
      height: 300px;
      position: relative;
      top: auto;
      left: auto;
    }
    &_cont {
      display: block;
      width: 100%;
      height: 100%;
      background: center center /cover no-repeat;
    }
  }

  &_cont {
    width: 37%;
    padding: 0 0;
    margin-left: auto;
    margin-right: 0;
    @include sp {
      width: 100%;
      background: $whiteBase;
      padding: 20px 5%;
      margin-top: -100px;
      position: relative;
      z-index: 1;
    }
  }
}
