.p-advisor{
  &_option {
    &_cont {
      margin-top: 56px;
      .c-circle {
        color: $greenDark;
        @include pc {
          padding-top: 30%;
        }
        @include sp {
          width: 70%;
          padding-top: 70%;
          margin: 0 auto;
          & + .c-circle {
            margin-top: 20px;
          }
        }
      }
      .c-textArea_title {
        text-align: center;
      }
    }
  }
}
