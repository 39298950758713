.p-newsDetail {
  &_date {
    padding-bottom: 14px;
  }
  &_title {
    padding: 40px 0;
  }
  &_cont {
    padding-top: 58px;
    @include fz(18, 16);
    @include lh(1.8, 1.8);
    h2 {
      @include fz(24, 20);
      @include lh(1.5, 1.5);
      color: $greenDark;
      margin-top: 40px;
      margin-bottom: 30px;
    }
    h3 {
      @include fz(24, 20);
      @include lh(1.5, 1.5);
      color: $greenDark;
      border-left: 4px solid $greenDark;
      padding-left: 16px;
      margin-top: 40px;
      margin-bottom: 30px;
    }
    p {
      margin-top: 30px;
    }

    ul, ol {
      margin-top: 30px;
      padding-left: 1em;
      li {
        & + & {
          margin-top: 6px;
        }
      }
    }
    ul {
      li {
        list-style: disc;
      }
    }
    ol {
      li {
        list-style: decimal;
      }
    }
    img {
      display: block;
      max-width: 400px;
      width: 90%;
      margin: 80px auto 70px;;
    }
    a {
      color: $greenDark;
      text-decoration: underline;
      display: inline-block;
      margin-top: 40px;
    }
  }
}
