.c-cardList {
  margin-top: 44px;
  @include pc {
    justify-content: center;
  }
  &_cmn {
    text-align: center;
    border: 4px solid $greenDark;
    padding: 30px 6px 40px;
    @include sp {
      padding: 20px 6px 20px;
    }
    & + & {
      @include pc {
        margin-left: 5%;
      }
      @include sp {
        margin-top: 20px;
      }
    }

    &_title {
      @include fz(50, 30);
      @include lh(1,1);
      font-family: $font_mont;
      color: $greenDark;
    }
    &_text {
      @include fz(18, 16, 700);
      @include lh(1.8,1.8);
      color: $greenDark;
      margin-top: 20px;
    }
  }
}
